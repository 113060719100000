<div class="main-body" *ngIf="!inIframe">
    <app-header [hideToggle]="true" (onClickLogo)="redirectToDashboard()" (reloadPage)="reloadCurrentRoute()"></app-header>
    <div class="main-body-scroll-area" style="height: calc(100% - 50px); overflow:auto;">
        <div class="main-view">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<router-outlet *ngIf="inIframe"></router-outlet>
